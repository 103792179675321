import React from "react"
import Layout from "../components/layout"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"

const JobsSection = styled.div`
  margin-top: 32px;
`

const Jobs = styled.div`
  grid-gap: 24px;
`

const Title = styled.h4`
  margin-bottom: 24px;
`

const Job = styled(Link)`
  color: #1e1a49;
  padding: 20px;
  border-radius: 16px;
  border: 1px solid #e7e7ec;
  background-color: #f4f4f9;
  transition: 0.3s;
  &:hover {
    box-shadow: 0px 3px 17px -14px rgba(0, 0, 0, 0.75);
  }
`

const JobTitle = styled.h6`
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`

const Location = styled.span`
  display: block;
  font-weight: 300;
`

export default () => {
  const {
    file: {
      childMarkdownRemark: { html, frontmatter },
    },
    jobs: { nodes: jobs },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "pages/careers.md" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            meta {
              title
              description
            }
          }
        }
      }
      jobs: allMarkdownRemark(filter: { frontmatter: { open: { eq: true } } }) {
        nodes {
          frontmatter {
            slug
            job {
              location
              team
              title
            }
          }
        }
      }
    }
  `)

  const groupedJobs = jobs.reduce((result, job) => {
    const temp = result.find(
      groupedJob => groupedJob.label === job.frontmatter.job.team
    ) || {
      data: [],
      label: job.frontmatter.job.team,
    }

    temp.data.push(job)

    result = result.filter(
      groupedJob => groupedJob.label !== job.frontmatter.job.team
    )

    result.push(temp)

    return result
  }, [])

  return (
    <Layout
      meta={{ title: frontmatter.title, description: frontmatter.description }}
    >
      <section>
        <div className="container">
          <div className="row">
            <div className="sub-page-heading">
              <h1>{frontmatter.title}</h1>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <br />
            {groupedJobs.length
              ? groupedJobs.map(team => (
                  <JobsSection key={team.label}>
                    <Title>{team.label}</Title>
                    <Jobs className="grid-3-col">
                      {team.data.map(({ frontmatter }) => (
                        <Job
                          to={`/careers/${frontmatter.slug}`}
                          key={frontmatter.slug}
                        >
                          <JobTitle>
                            {frontmatter.job.title} <span>></span>
                          </JobTitle>
                          <Location>{frontmatter.job.location}</Location>
                        </Job>
                      ))}
                    </Jobs>
                  </JobsSection>
                ))
              : null}
          </div>
        </div>
      </section>
    </Layout>
  )
}
